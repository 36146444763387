import * as React from "react"
import { useEffect, useState } from "react"
import { debounce } from "lodash"
import "../../../assets/styles/page/docSearch.scss"
import ArticleCard from "../../../components/ArticleCard"
import { Link } from "gatsby"
import Layout from "../../../components/layout"

interface ContentItem {
  titleOfArticle?: string;
  titleOfProject?: string;
  videoTitle?: string;
  bannerUrl?: {
    url: string;
  };
  videoThumbnail?: {
    url: string;
  };
  metaTitle?: string;
  projectDescription?: string;
  contentCategory: string;
  metaDescription?: string;
  videoCategory?: string;
  ProjectCategory?: string;
  _id: string;
  slug: string;
}

interface SearchItem {
  title: string;
  subtitle: string;
  category: string;
  image: string;
  id: string;
  slug: string;
  contentCategory: string;
}

const DocSearch = () => {
  const [query, setQuery] = useState("")
  const [items, setItems] = useState<ContentItem[]>([])
  const [newList, setNewList] = useState<SearchItem[]>([])
  const [filteredItems, setFilteredItems] = useState<SearchItem[]>([])

  useEffect(() => {
    const fetchContent = async () => {
      const query = `
        query articleListAndprojectlistAndVideoList {
          articleCollection {
            items {
              titleOfArticle
              metaTitle
              metaDescription
              _id
              contentCategory
              slug
              bannerUrl {
                url
              }
            }
          }

          projectCollection {
            items {
              bannerUrl {
                url
              }
              _id
              titleOfProject
              projectDescription
              projectCategory
              contentCategory
              slug
            }
          }

          videoCollection {
            items {
              videoCategory
              videoTitle
              contentCategory
              slug
              _id
              videoThumbnail {
                url
              }
              metaTitle
            }
          }
        }
      `
      try {
        const { default: ContentfulService } = await import("../../../services/contentful.service")
        const response = await ContentfulService.callContentfulApi(query)
        const allItems = [
          ...(response.data.articleCollection?.items || []),
          ...(response.data.projectCollection?.items || []),
          ...(response.data.videoCollection?.items || [])
        ]
        setItems(allItems)

        const formattedList = allItems.map(item => ({
          title: item.titleOfArticle || item.titleOfProject || item.videoTitle || '',
          image: item.bannerUrl?.url || item.videoThumbnail?.url || '',
          subtitle: item.metaTitle || item.projectDescription || '',
          contentCategory: item.contentCategory || '',
          category: item.metaDescription || item.videoCategory || item.ProjectCategory || '',
          id: item._id,
          slug: getSlugPath(item)
        }))
        setNewList(formattedList)
        setFilteredItems(formattedList)
      } catch (error) {
        console.error('Error fetching content:', error)
      }
    }

    fetchContent()
  }, [])

  const getSlugPath = (item: ContentItem) => {
    switch (item.contentCategory) {
      case 'article':
        return `/doc/article/doc-article/${item.slug}`
      case 'project':
        return `/doc/project/doc-projects/${item.slug}`
      case 'video':
        return `/doc/article/doc-videos/${item.slug}`
      default:
        return ''
    }
  }

  const getFilteredContent = (query: string, list: SearchItem[]) => {
    if (!query) {
      return list
    }
    return list.filter(
      t => t.title.toLowerCase().includes(query.toLowerCase()) || t.subtitle.toLowerCase().includes(query.toLowerCase())
    )
  }

  const getFilteredArticles = () => {
    setFilteredItems(newList.filter(t => t.contentCategory === 'article'))
  }

  const getFilteredProject = () => {
    setFilteredItems(newList.filter(t => t.contentCategory === 'project'))
  }

  const getFilteredVideo = () => {
    setFilteredItems(newList.filter(t => t.contentCategory === 'video'))
  }

  const getAll = () => {
    setFilteredItems(newList)
  }

  const filterArticles = debounce((e: string) => {
    setFilteredItems(getFilteredContent(e, newList))
  }, 100)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
    filterArticles(e.target.value)
  }

  return (
    <>
      <Layout>
        <div className="search--page--container">
          <div className="search--input--wrapper">
            <div className="search--input--icon">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                  stroke="#2b2b2b"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.0004 20.9999L16.6504 16.6499"
                  stroke="#2b2b2b"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <input type="search" className="search--input" placeholder="Search" value={query} onChange={handleChange} />
          </div>
          <div className="search--selection">
            <div onClick={getAll} className="search--option">
              All
            </div>
            <div onClick={getFilteredArticles} className="search--option">
              Article
            </div>
            <div onClick={getFilteredProject} className="search--option">
              Project
            </div>
            <div onClick={getFilteredVideo} className="search--option">
              Videos
            </div>
          </div>
          <div className="searching--items--containers">
            {filteredItems.map(value => {
              const { id, title, category, image, subtitle, slug } = value
              return (
                <Link to={slug} className="search--artile--card--wrapper" key={id}>
                  <ArticleCard
                    key={id}
                    articleImage={image}
                    articleHeadtext={title}
                    articleSubtext={subtitle}
                    meta={category}
                  />
                </Link>
              )
            })}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default DocSearch
